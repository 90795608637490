.App {
  /* text-align: center; */
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.AppContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.App-logo {
  height: 12vmin;
  pointer-events: none;
  margin-bottom: 10px;
}

.App-banner-text {
  font-size: 1.5em;
}

.App-instructions {
  font-size: 0.6em;
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;
}

.App-header {
  flex-direction: column;
  display: flex;
  align-items: center;
  padding: 25px 0;
}

.App-link {
  color: #61dafb;
}

.AddressesText {
  width: 45em;
}

.Footer {
  flex-direction: column;
  display: flex;
  font-size: 0.6em;
  align-items: center;
  padding-bottom: 1em;
}

@media only screen and (max-width: 600px) {
  .AddressesText {
    width: 100%;
  }
}

.InputContainer {
}

.DownloadLink {
  color: #B1B1B1;
  margin-top: 1em;
}

.Checkboxes {
  color: #B1B1B1;
  font-size: 0.8em;
}

.DonationLink {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 39em;
  text-align: center;
}

.DonationQR {
  background: #FFF;
  padding: 4px;
  max-width: 80px;
  max-height: 80px;
  margin-bottom: 0.5em;
  transition: all .2s ease-in-out;
}

.DonationQR:hover {
  transform: scale(1.5);
}

.SuggestionSection {
  align-items: center;
  padding-top: 1em;
}

a {
  color: #B1B1B1;
}

.row {
  flex-direction: row;
  display: flex;
}

.column {
  flex-direction: column;
  display: flex;
}
